import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "brew-day-in-may----first-batch-in-2019"
    }}>{`Brew Day in May -- First Batch in 2019`}</h1>
    <h2 {...{
      "id": "yeast"
    }}>{`Yeast`}</h2>
    <p>{`White Labs California Ale WLP001
Starter made for 24 hours, stirred`}</p>
    <p>{`room temperature: 25`}{`°`}{`C`}</p>
    <h4 {...{
      "id": "before-inoculation"
    }}>{`Before inoculation:`}</h4>
    <ul>
      <li parentName="ul">{`gravity 1.040
temperature:`}</li>
    </ul>
    <h4 {...{
      "id": "after-inoculation"
    }}>{`After inoculation:`}</h4>
    <p>{`gravity 1.010
temperature:
20 hours cooling in fridge.`}</p>
    <h2 {...{
      "id": "malt"
    }}>{`Malt`}</h2>
    <ul>
      <li parentName="ul">{`Biscuit 45-55 EBC 720g`}</li>
      <li parentName="ul">{`Caramel Hell 20-30 EBC 1450g`}</li>
      <li parentName="ul">{`Pale ale malt 5,5 - 7,5 EBC 6800g`}</li>
      <li parentName="ul">{`China pilsner malt 5,5 250 g`}</li>
    </ul>
    <p>{`EBC of beer `}<inlineCode parentName="p">{`(720 * 50 + 1450 * 25 + 6,5 * 6800) / ( 720 + 1450 + 6800)`}</inlineCode>{` = 13`}</p>
    <p>{`mashing water 18.25  + 5 L
sparging water 25 L`}</p>
    <p>{`clean the keg and fermentor at the same time`}</p>
    <ul>
      <li parentName="ul">{`mashing in 66 `}{`°`}{`C for 40 minutes (next time better 65 `}{`°`}{`C)`}</li>
      <li parentName="ul">{`20 minutes first verzuckersrast 72`}{`°`}{`C`}</li>
    </ul>
    <p>{`Abmaischen 15 minutes `}</p>
    <p>{`lautern`}</p>
    <h2 {...{
      "id": "hops"
    }}>{`Hops`}</h2>
    <p>{`80g Perle
25g Citra
10g Cascade`}</p>
    <p>{`It cost 60 minutes in total to boil.`}</p>
    <p>{`divide into 3 parts. `}</p>
    <ul>
      <li parentName="ul">{`at first add 1 parts, `}</li>
      <li parentName="ul">{`after 20 minutes add second parts, `}</li>
      <li parentName="ul">{`after 30 minutes, 10 minutes before ending, add the last part of hops. `}</li>
    </ul>
    <p>{`IBU: Perle 28.76 + Citra 12.08 + Cascade 4.99 = 45.83`}</p>
    <h2 {...{
      "id": "cooling"
    }}>{`Cooling`}</h2>
    <ul>
      <li parentName="ul">{`Let the water flow through the air, let the air take the heat away.`}</li>
      <li parentName="ul">{`prepare more ice`}</li>
      <li parentName="ul"></li>
    </ul>
    <h2 {...{
      "id": "fermentation"
    }}>{`Fermentation`}</h2>
    <p>{`Gravity before fermentation: 1.046`}</p>
    <h4 {...{
      "id": "cautions"
    }}>{`cautions:`}</h4>
    <ul>
      <li parentName="ul">{`need a airlock for keg`}</li>
      <li parentName="ul"></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      